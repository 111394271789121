import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiButton } from '@elastic/eui';
import NavButtons from 'components/Downloads/Outdoor_Cameras/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-5905 HD Downloads",
  "path": "/Downloads/Outdoor_Cameras/IN-5905_HD/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR Firmware, WebUI and Documentation Downloads",
  "image": "./P_SearchThumb_IN-5905HD_Downloads.png",
  "social": "/images/Search/P_SearchThumb_IN-5905HD_Downloads.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Downloads_white.webp",
  "chapter": "Downloads"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Download Area - IN-5905 HD' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='Software Downloads and Usermanuals for your INSTAR Products' image='/images/Search/P_SearchThumb_IN-5905HD_Downloads.png' twitter='/images/Search/P_SearchThumb_IN-5905HD_Downloads.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Downloads/Aussenkameras/IN-5905_HD/' locationFR='/fr/Downloads/Outdoor_Cameras/IN-5905_HD/' crumbLabel="IN-5905" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "downloads",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#downloads",
        "aria-label": "downloads permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Downloads`}</h1>
    <h2 {...{
      "id": "outdoor-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#outdoor-cameras",
        "aria-label": "outdoor cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outdoor Cameras`}</h2>
    <h2 {...{
      "id": "in-5905-hd-downloads",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-5905-hd-downloads",
        "aria-label": "in 5905 hd downloads permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-5905 HD Downloads`}</h2>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#software-installation-wizard"
        }}>{`Software Installation Wizard`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#instructions"
        }}>{`Instructions`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#quick-installation"
            }}>{`Quick Installation`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#camera-software-updates"
        }}>{`Camera software updates`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#firmware"
            }}>{`Firmware`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#download"
                }}>{`Download`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "software-installation-wizard",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#software-installation-wizard",
        "aria-label": "software installation wizard permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software Installation Wizard`}</h2>
    <p>{`Every INSTAR product comes with an installation CD. The CD contains an installation assistant that guides you through the installation of your new INSTAR product. The installation wizard can also be called directly by clicking on the link below and opening the CD online. The wizard contains all the information and current download links you need for the installation.`}</p>
    <p>{`The software CD includes an HTML setup wizard that guides you through the required settings and displays the current download links for your INSTAR product.`}</p>
    <p><strong parentName="p">{`Quick Installation Wizard`}</strong>{`: The wizard guides you through the most important steps to get your INSTAR product up and running.`}</p>
    <p><strong parentName="p">{`Software Installation`}</strong>{`: You will find download links if your INSTAR product requires additional software. You are guided through all steps after you have completed the download and the installation.`}</p>
    <p><strong parentName="p">{`Firmware Updates`}</strong>{`: INSTAR provides firmware and WebUI updates for its products at regular intervals. Below you will find the latest versions of the firmware and WebUI which you can upload to your INSTAR camera.`}</p>
    <OutboundLink href="https://install.instar.com/" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink"><EuiButton fill mdxType="EuiButton">Start Installation</EuiButton></OutboundLink>
    <h2 {...{
      "id": "instructions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instructions",
        "aria-label": "instructions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Instructions`}</h2>
    <h3 {...{
      "id": "quick-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#quick-installation",
        "aria-label": "quick installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Quick Installation`}</h3>
    <p>{`The user manual contains detailed information about all camera functions.`}</p>
    <p><strong parentName="p">{`Quick Installation`}</strong>{`: The Quick Installation section provides an overview of all necessary steps to install your camera via LAN cable, WLan and over the Internet.`}</p>
    <p><strong parentName="p">{`The web interface`}</strong>{`: The web interface area gives you an overview of all the functions of the camera and how to configure it when you access the camera with the web browser.`}</p>
    <p><strong parentName="p">{`Technical specifications`}</strong>{`: You will find a list of all technical specifications of your IP camera.`}</p>
    <p><strong parentName="p">{`Product features and safety warnings`}</strong></p>
    <p><strong parentName="p">{`Windows software, smartphone apps and video streaming`}</strong></p>
    <p><strong parentName="p">{`Camera reset, lens adjustment, warranty and disposal`}</strong></p>
    <p><strong parentName="p">{`FAQ questions and answers`}</strong></p>
    <Link to="https://wiki.instar.com/dl/IN-5905HD/QuickInstallation-IN-5905HD_EN-DE.pdf" target="_blank" rel="noopener noreferrer" mdxType="Link"><EuiButton fill mdxType="EuiButton">Anleitung</EuiButton></Link>
    <h2 {...{
      "id": "camera-software-updates",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#camera-software-updates",
        "aria-label": "camera software updates permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Camera software updates`}</h2>
    <h3 {...{
      "id": "firmware",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#firmware",
        "aria-label": "firmware permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Firmware`}</h3>
    <p>{`You will always find here the latest version and all previous versions of the firmware for your INSTAR Camera. If you plan to update the WebUI, make sure that you have installed the latest version of the firmware. In most cases, the new features in the web interface (WebUI) also require the latest firmware. For information on how to update the firmware please look `}<a parentName="p" {...{
        "href": "/en/Quick_Installation/WebUI_And_Firmware_Upgrade/"
      }}>{`Here`}</a>{`.`}</p>
    <p><strong parentName="p">{`Important`}</strong>{`: Unzip the * .ZIP file after downloading. Please note that you must first install the latest firmware and then the WebUI.`}</p>
    <h4 {...{
      "id": "download",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#download",
        "aria-label": "download permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Download`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/IN-5905HD/IN-5905_FW_3.1.2.69_WebUI_1.22(756)_update.zip"
        }}>{`FW 3.1.2.69 WebUI 1.22`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Descriptions updated`}</li>
          <li parentName="ul">{`P2P libraries updated`}</li>
          <li parentName="ul">{`System Log not shown /fixed`}</li>
          <li parentName="ul">{`General bugfixes and minor improvements`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/IN-5905HD/IN-5905_FW_3.1.2.69_WebUI_1.21.(751)_update.zip"
        }}>{`FW 3.1.2.69 WebUI 1.21`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Alarm videos up to 45 seconds`}</li>
          <li parentName="ul">{`Touch support for alarm areas`}</li>
          <li parentName="ul">{`The IP address can now be recorded in the logbook when logging in - Can be activated under User administration`}</li>
          <li parentName="ul">{`The triggering alarm area is logged even when the alarm is coupled.`}</li>
          <li parentName="ul">{`A PUSH test has been added. The QR code must first be scanned in the APP.`}</li>
          <li parentName="ul">{`WLAN and network configurations can be excluded from factory reset`}</li>
          <li parentName="ul">{`Added CGI command for manual alarm triggering - `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=pushhostalarm`}</code></li>
          <li parentName="ul">{`Camera uses a unique hostname for DHCP service.`}</li>
          <li parentName="ul">{`Functionalities have been implemented to restore cameras that have been updated incorrectly. To make sure that these functionalities are available, a downgrade to older versions than `}<em parentName="li">{`1.21 (751)`}</em>{` is no longer possible.`}</li>
          <li parentName="ul">{`General bugfixes and improvements`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/IN-5905HD/IN-5905_FW_3.1.2.68_WebUI_1.20.(736)_update.zip"
        }}>{`FW 3.1.2.68 WebUI 1.20`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Simplified 1-click update method`}</li>
          <li parentName="ul">{`Offline update via PKG file still possible`}</li>
          <li parentName="ul">{`Alarm ranges can be configured in 2 groups by time`}</li>
          <li parentName="ul">{`General bugfixes and improvements`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/IN-5905HD/IN-5905_FW_3.1.2.66_WebUI_1.19.(653)_update.zip"
        }}>{`FW 3.1.2.66 WEBUI 1.19`}</a>
        <ul parentName="li">
          <li parentName="ul">{`P2P Improvements`}</li>
          <li parentName="ul">{`General Bugfixes and Improvements`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/IN-5905HD/IN-5905_FW_3.1.2.66_WebUI_1.18(653)_update.zip"
        }}>{`FW 3.1.2.66 WEBUI 1.18`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Fixed problem with P2P`}</li>
          <li parentName="ul">{`General bug fixes and improvements`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/IN-5905HD/IN-5905_FW_3.1.2.66_WebUI_1.17.(634)_update.zip"
        }}>{`FW 3.1.2.66 WEBUI 1.17`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Fixed problem with automatic summer / winter changeover`}</li>
          <li parentName="ul">{`Fixed problem with which the alarm sound on the IN-6014HD could not be deactivated`}</li>
          <li parentName="ul">{`New validation integrated with input fields`}</li>
          <li parentName="ul">{`Security gap closed`}</li>
          <li parentName="ul">{`General bug fixes and improvements`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/IN-5905HD/IN-5905_FW_3.1.2.64_WebUI_1.16.(620)_update.zip"
        }}>{`FW 3.1.2.64 WEBUI 1.16`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Fixed problem with P2P connection`}</li>
          <li parentName="ul">{`Improvement in P2P connection speed`}</li>
          <li parentName="ul">{`New INSTAR Cloud recording platform now available`}</li>
          <li parentName="ul">{`Custom NTP time server is now possible`}</li>
          <li parentName="ul">{`Problem with FTP upload fixed`}</li>
          <li parentName="ul">{`Problem with user name and password authentication with MJPEG Stream and snap.jpg fixed`}</li>
          <li parentName="ul">{`Fixed problem with the camera restarting with a port change`}</li>
          <li parentName="ul">{`Special characters Problem with WLAN, DDNS, FTP and Cloud passwords fixed`}</li>
          <li parentName="ul">{`Adjustment Strings / Designations`}</li>
          <li parentName="ul">{`General bug fixes and improvements`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/IN-5905HD/IN-5905_FW_3.1.2.64_WebUI_1.15(617)_update.zip"
        }}>{`FW 3.1.2.64 WEBUI 1.15`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Bugfixes`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/IN-5905HD/IN-5905_FW_3.1.2.64_WebUI_1.14(604)_update.zip"
        }}>{`FW 3.1.2.64 WEBUI 1.14`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Fixed problem with P2P connection`}</li>
          <li parentName="ul">{`Custom NTP Time Server`}</li>
          <li parentName="ul">{`Problem with FTP upload fixed`}</li>
          <li parentName="ul">{`Fixed problem with cloud activation`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/IN-5905HD/IN-5905_FW_3.1.2.63_WebUI_1.13(586)_update.zip"
        }}>{`FW 3.1.2.63 WEBUI 1.13`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Integration of the new INSTAR Cloud platform`}</li>
          <li parentName="ul">{`LAN and WLAN Mac address are now both displayed under "System info" (if the camera model has WLAN)`}</li>
          <li parentName="ul">{`Change pre-selection for GMX`}</li>
          <li parentName="ul">{`Display of LAN and WLAN MAC address`}</li>
          <li parentName="ul">{`The preselection for GMX in the email was changed by mail.gmx.net on mail.gmx.de to avoid problems with the Telekom Speedport`}</li>
          <li parentName="ul">{`General bug fixes and improvements`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/IN-5905HD/IN-5907_FW_3.1.2.56_WebUI_1.12(555)_update.zip"
        }}>{`FW 3.1.2.56 WEBUI 1.12`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Alarm Server`}</li>
          <li parentName="ul">{`Daily time-controlled camera reboot`}</li>
          <li parentName="ul">{`Email SMTP sending without authentication`}</li>
          <li parentName="ul">{`New Installation Wizard`}</li>
          <li parentName="ul">{`Users & guest accounts can be disabled`}</li>
          <li parentName="ul">{`German syslog`}</li>
          <li parentName="ul">{`Fixed problem with 'empty' camera name`}</li>
          <li parentName="ul">{`H264 video support for all ports in user and guest access`}</li>
          <li parentName="ul">{`Fixed problem with determining email providers (e.g., GMX, web.de, Gmail, AOL)`}</li>
          <li parentName="ul">{`Problem fixed that the alarm input, audio alarm and the motion detection were not separate usable when activated`}</li>
          <li parentName="ul">{`General bug fixes and improvements`}</li>
          <li parentName="ul">{`Camera parking position for all PTZ models`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/IN-5905HD/IN-5905_FW_3.1.2.45_WebUI_1.11.(431)_update.zip"
        }}>{`FW 3.1.2.45 WEBUI 1.11`}</a>
        <ul parentName="li">
          <li parentName="ul">{`New h.264 video stream via RTMP protocol via the RTMP port (see Network> IP configuration) with Flash Plugin support`}</li>
          <li parentName="ul">{`Two different MotionJPEG stream variants`}</li>
          <li parentName="ul">{`Privacy Mask - Set up to 4 private areas that are completely hidden in the video image`}</li>
          <li parentName="ul">{`Camera settings are no longer deleted with the update`}</li>
          <li parentName="ul">{`h.264 Video support for Linux using Flash`}</li>
          <li parentName="ul">{`Manual recording function on the built-in memory card of the camera`}</li>
          <li parentName="ul">{`Folder structure under FTP`}</li>
          <li parentName="ul">{`Manually open (gray) and close (red) the alarm output relay via the web interface`}</li>
          <li parentName="ul">{`Cameras can be connected to a WLAN at WLAN`}</li>
          <li parentName="ul">{`General bug fixes and improvements`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/IN-5905HD/IN-5905_FW_3.1.2.37_WebUI_1.9.(213)_update.zip"
        }}>{`FW 3.1.2.37 WEBUI 1.9`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Push notifications are possible with the app InstarVision`}</li>
          <li parentName="ul">{`ActiveX Plugin Support for Chrome and Firefox under Windows`}</li>
          <li parentName="ul">{`New NTP servers`}</li>
          <li parentName="ul">{`Troubleshooting problems with the 1und1 Mailserver`}</li>
          <li parentName="ul">{`New INSTAR Email Server is available for mail delivery`}</li>
          <li parentName="ul">{`A second email recipient can be entered`}</li>
          <li parentName="ul">{`Time-controlled activation and deactivation of the IR LEDs`}</li>
          <li parentName="ul">{`PTZ limitation (for cameras with PTZ)`}</li>
          <li parentName="ul">{`Move the alarm position (for cameras with PTZ)`}</li>
          <li parentName="ul">{`Acoustic alarm signal for movement detection via cameralautsprecher or audio output`}</li>
          <li parentName="ul">{`automated test with WLAN`}</li>
          <li parentName="ul">{`Automatic check of the SD card`}</li>
          <li parentName="ul">{`Improvements, bug fixes and TYPOS`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      